import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper";

import { Props } from "../../types/Slider02";

// Import Swiper styles
import "swiper/css/bundle";
// import "swiper/swiper-bundle.min.js";

import * as SliderStyles from "./Slider02.module.scss";
import { WpImage } from "../WpImage";

/**
 * サムネイルつきスライダーを返します。
 * 詳しくはswiper公式を参照ください。
 */
export const Presenter: React.VFC<Props> = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="w-[530px] max-w-full">
      {/* Main Swiper -> pass thumbs swiper instance */}
      <Swiper
        modules={[Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        loop={true}
        className={`mb-6 ${SliderStyles.swiperContainer}`}
      >
        {/* ... */}
        {Object.values(images).map(
          (image, index) =>
            image && (
              <SwiperSlide key={index} className="w-10">
                <WpImage image={image} className={``} />
              </SwiperSlide>
            )
        )}
      </Swiper>

      {/* Thumbs Swiper -> store swiper instance */}
      {/* It is also required to set watchSlidesProgress prop */}
      <Swiper
        modules={[Thumbs]}
        // watchSlidesProgress
        slidesPerView={`auto`}
        className={SliderStyles.swiperContainerThumbs}
        onSwiper={setThumbsSwiper}
        // allowSlidePrev={false}
        // allowSlideNext={false}
      >
        {/* ... */}
        {Object.values(images).map(
          (image, index) =>
            image && (
              <SwiperSlide key={index}>
                <WpImage image={image} className={`h-full w-full`} />
              </SwiperSlide>
            )
        )}
      </Swiper>
    </div>
  );
};
