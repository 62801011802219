import React from "react";

import { Presenter } from "./Presenter";
import { graphql, useStaticQuery } from "gatsby";

import { WpImageType } from "../../types/WpImage";

export const Table01: React.VFC = () => {
  // 取得したデータを画像部分のみ抽出
  const queryImagesData =
    useStaticQuery<GatsbyTypes.TableImagesQuery>(query).wpPage
      ?.productsComparisonTable?.images;

  // 配列化
  const arrayImgData = queryImagesData && Object.entries(queryImagesData);

  // headsは製品名、画像のパス、詳しくみるのリンク先
  // この後に上のarrayImgDataをpushする
  const heads: Array<[string, string, WpImageType | undefined]> = [
    ["キャスコーナー<br/>M級", "/products/product120", undefined],
    ["キャスコーナー<br/>H級", "/products/product158", undefined],
    ["サイレント<br/>キャスコーナー", "/products/product108", undefined],
    ["セフティ・スロープ", "/products/product161", undefined],
    ["ハイステップ<br/>コーナー", "/products/product165", undefined],
    ["ライトステップ<br/>コーナー", "/products/product1116", undefined],
    ["アルミスロープ", "/products/product168", undefined],
    ["フリースロープ", "/products/product172", undefined],
    ["安心スロープ", "/products/product175", undefined],
  ];

  // 表のtd部分
  const rows = [
    [
      "ダクタイル<br/>鋳鉄製",
      "ダクタイル<br/>鋳鉄製",
      "ゴム製",
      "ゴムチップ製",
      "プラスチック製",
      "プラスチック製",
      "アルミ製",
      "PPハニカムコア材<br/>アルミ製",
      "合成樹脂製",
    ],
    [
      "<span class='text-primary font-semibold text-[20px] pc:text-[36px]'>8t</span>車",
      "<span class='text-primary font-semibold text-[20px] pc:text-[36px]'>30t</span>車",
      "<span class='text-primary font-semibold text-[20px] pc:text-[36px]'>4t</span>車",
      "<span class='text-primary font-semibold text-[20px] pc:text-[36px]'>6t</span>車",
      "<span class='text-primary font-semibold text-[20px] pc:text-[36px]'>4t</span>車<br />HSL100のみ2t",
      "<span class='text-primary font-semibold text-[20px] pc:text-[36px]'>2t</span>車",
      "<span class='text-primary font-semibold text-[16px] pc:text-2xl'>150kg</span><br />~<br /><span class='text-primary font-semibold text-[16px] pc:text-2xl'>300kg</span>",
      "<span class='text-primary font-semibold text-[16px] pc:text-2xl'>150kg</span><br />~<br /><span class='text-primary font-semibold text-[16px] pc:text-2xl'>300kg</span>",
      "<span class='text-dark text-2xl'>-</span>",
    ],
    [
      "<span class='text-primary text-[20px] pc:text-[40px] font-bold'>&#x25CE;</span>",
      "<span class='text-primary text-[20px] pc:text-[40px] font-bold'>&#x25CE;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25CB;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25CB</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25CB</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25B3;</span>",
      "<span class='text-primary text-[20px] pc:text-[40px] font-bold'>&#x25CE;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25CB;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25CB;</span>",
    ],
    [
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25B3;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25B3;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25CB;</span>",
      "<span class='text-primary text-[20px] pc:text-[40px] font-bold'>&#x25CE;</span>",
      "<span class='text-primary text-[20px] pc:text-[40px] font-bold'>&#x25CE;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25B3;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25B3;</span>",
      "<span class='text-dark text-[18px] pc:text-[36px]'>&#x25B3;</span>",
      "<span class='text-dark text-2xl'>-</span>",
    ],
  ];

  if (heads.length === arrayImgData?.length) {
    heads.map((head, index) => {
      head.splice(2, 1, arrayImgData[index][1]);
    });
  } else {
    console.log("写真の数と表の列の数が合いません");
  }

  return <Presenter heads={heads} rows={rows} />;
};

// page id cG9zdDozODM=
const query = graphql`
  query TableImages {
    wpPage(id: { eq: "cG9zdDozODM=" }) {
      productsComparisonTable {
        images {
          img01 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          img02 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          img03 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          img04 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          img05 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          img06 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          img07 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          img08 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          img09 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
