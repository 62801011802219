import React from "react";
import { Props } from "../../types/Table02";

/**
 * 表を返します。
 * 汎用的な2色の表です。
 */
export const Presenter: React.VFC<Props> = ({ head, body }) => {
  if (head?.length === 0 && !body) {
    return null;
  }

  return (
    <div className="inline-block overflow-hidden rounded-xl border border-[#E0E0E0]">
      <table className="border-collapse">
        <thead>
          <tr className="bg-primary text-sm font-bold text-white  pc:text-base">
            {head?.map(
              (th, index) =>
                th && (
                  <th
                    key={index}
                    className="px-3 py-4 text-center"
                    dangerouslySetInnerHTML={{
                      __html: th,
                    }}
                  ></th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {body?.map((tr, index) =>
            index % 2 ? (
              // 偶数行はグリーン
              <tr
                key={index}
                className="border-t border-t-[#E0E0E0] bg-secondary text-sm text-dark pc:text-base"
              >
                {tr?.map(
                  (td, indextd) =>
                    td && (
                      <td
                        key={indextd}
                        className="px-3 py-4 text-center"
                        dangerouslySetInnerHTML={{
                          __html: td,
                        }}
                      ></td>
                    )
                )}
              </tr>
            ) : (
              // 奇数行はホワイト
              <tr
                key={index}
                className="bg-white text-sm text-dark pc:text-base"
              >
                {tr?.map(
                  (td, indextd) =>
                    td && (
                      <td
                        key={indextd}
                        className="px-3 py-4 text-center"
                        dangerouslySetInnerHTML={{
                          __html: td,
                        }}
                      ></td>
                    )
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};
