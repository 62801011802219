import React from "react";

import { Props } from "../../types/RuledLineList02";

/**
 * 罫線付きリストを返します。dlでラップしてください。
 * 製品詳細データの表示リストなどに使用。
 * dtは細文字です
 */
export const Presenter: React.VFC<Props> = ({ title, content }) => {
  return (
    <div className="flex items-center border-b border-solid border-[#E0E0E0] py-4">
      <dt className="min-w-[80px] text-sm text-dark pc:text-base">{title}</dt>
      <dd className="ml-8 text-sm text-dark pc:text-base">{content}</dd>
    </div>
  );
};
