import React, { useEffect } from "react";

import { Props } from "../../types/LineUp";
import { Link01 } from "../Link01";
import { Table02 } from "../Table02";
import { WpImage } from "../WpImage";
import ScrollHint from "scroll-hint";

/**
 * ラインナップ
 * ulでラップして使用
 */
export const Presenter: React.VFC<Props> = ({
  image,
  image2,
  heading,
  link,
  table,
  freeblock,
}) => {
  useEffect(() => {
    new ScrollHint(".js-scrollable", {
      i18n: {
        scrollable: "スクロールできます",
      },
    });
  }, []);

  return (
    <li className="flex flex-col items-center overflow-hidden pc:flex-row pc:items-start">
      <div className="w-full flex-shrink-0 pc:w-[430px]">
        {image && <WpImage image={image} />}
        {image2 && <WpImage image={image2} className="mt-4" />}
      </div>
      <div className="mt-8 max-w-full tb:max-w-[530px] pc:ml-8 pc:mt-0">
        {/* 見出し */}
        {heading && (
          <h3
            className="relative pl-5 text-xl font-bold before:absolute
        before:top-1/2 before:left-0 before:h-2 before:w-2 before:-translate-y-1/2
        before:rounded-full before:bg-primary before:content-[''] pc:text-2xl"
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          ></h3>
        )}

        {/* 表 */}
        {(table?.header && table?.header[0]?.length === 0) || (
          <div className="js-scrollable mt-4 pc:max-w-[480px]">
            <div className="whitespace-nowrap">
              <Table02 head={table?.header} body={table?.body} />
            </div>
          </div>
        )}

        {/* フリーブロック */}
        {freeblock && (
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{
              __html: freeblock,
            }}
          ></div>
        )}

        {link && (
          <div className="mt-3 text-xs pc:mt-[14px] pc:text-sm">
            <Link01 label="設計図を見る" link={link} />
          </div>
        )}
      </div>
    </li>
  );
};
