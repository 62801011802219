import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/Layout";
import { RuledLineList03 } from "../components/RuledLineList03";
import { Heading03 } from "../components/Heading03";
import { ProductInfo01 } from "../components/ProductInfo01";
import { LineUp } from "../components/LineUp";
import { Difference } from "../components/Difference";
import { FreeBlock01 } from "../components/FreeBlock01";
import { Cta02 } from "../components/Cta02";
import { Table01 } from "../components/Table01";
import { Breadcrumb } from "../components/Breadcrumb";
import { Triangle } from "../components/Triangle";
import TextLoop from "../components/TextLoop";

const PostProduct: React.FC<PageProps<GatsbyTypes.PostProductQuery>> = ({
  data,
}) => {
  const ACF = data.wpProduct?.productAcf;

  const differences = data.wpProduct?.productAcf?.differences;

  return (
    <Layout
      title={data.wpProduct?.title}
      description={`ミスギの${data.wpProduct?.title}の製品ページです。`}
    >
      <article className="">
        <div className="l-container">
          <Breadcrumb
            labels={[
              { url: "/products", text: "製品一覧" },
              {
                url: "",
                text: data.wpProduct?.title ? data.wpProduct?.title : "",
              },
            ]}
          />
        </div>
        <div className="relative bg-[#FAFAFA] py-10 pc:py-[72px]">
          <div className="l-container-sm">
            <ProductInfo01
              smallLabel={ACF?.enname}
              images={ACF?.images}
              label={data.wpProduct?.title}
              overviews={ACF?.overview}
              colors={ACF?.colors}
              price={ACF?.price}
              notaxprice={ACF?.notaxprice}
              caution={ACF?.caution}
            />
          </div>
        </div>
        {ACF?.isshowfeatures && (
          <div className="l-container-sm relative pt-20 pb-44">
            <Heading03 label="製品の特長" bigLabel="PRODUCT FEATURES" />
            <ul>
              {ACF?.features &&
                Object.values(ACF?.features).map(
                  (feature, index) =>
                    feature?.heading && (
                      <RuledLineList03
                        key={index}
                        title={feature.heading}
                        index={index + 1}
                      />
                    )
                )}
            </ul>
            <Triangle className="top-[210px] right-[-25px] h-[318px] w-[212px] pc:top-[100px] pc:right-[-263px] pc:h-[547px] pc:w-[366px]" />
            <Triangle className="bottom-[160px] left-0 h-[318px] w-[212px] pc:bottom-[260px] pc:left-[-263px] pc:h-[547px] pc:w-[366px]" />
          </div>
        )}

        <div className="relative bg-[#FAFAFA]">
          <div className="absolute top-0 -translate-y-1/2">
            <TextLoop
              className="text-[112px] text-white pc:text-[200px]"
              text="MISUGI"
              design={2}
            />
          </div>
          <div className="l-container-sm pt-32 pb-24">
            <Heading03
              label="ラインナップ"
              bigLabel="LINE UP"
              isCenter={true}
            />
            <ul className="mt-20 space-y-20">
              {ACF?.lineup?.item01?.title && (
                <LineUp
                  image={ACF?.lineup?.item01?.img}
                  image2={ACF?.lineup?.item01?.img2}
                  heading={ACF?.lineup?.item01?.title}
                  link={ACF?.lineup?.item01?.link?.url}
                  table={ACF?.lineup?.item01?.table}
                  freeblock={ACF?.lineup?.item01?.freeblock}
                />
              )}

              {ACF?.lineup?.item02?.title && (
                <LineUp
                  image={ACF?.lineup?.item02?.img}
                  image2={ACF?.lineup?.item02?.img2}
                  heading={ACF?.lineup?.item02?.title}
                  link={ACF?.lineup?.item02?.link?.url}
                  table={ACF?.lineup?.item02?.table}
                  freeblock={ACF?.lineup?.item02?.freeblock}
                />
              )}

              {ACF?.lineup?.item03?.title && (
                <LineUp
                  image={ACF?.lineup?.item03?.img}
                  image2={ACF?.lineup?.item03?.img2}
                  heading={ACF?.lineup?.item03?.title}
                  link={ACF?.lineup?.item03?.link?.url}
                  table={ACF?.lineup?.item03?.table}
                  freeblock={ACF?.lineup?.item03?.freeblock}
                />
              )}

              {ACF?.lineup?.item04?.title && (
                <LineUp
                  image={ACF?.lineup?.item04?.img}
                  image2={ACF?.lineup?.item04?.img2}
                  heading={ACF?.lineup?.item04?.title}
                  link={ACF?.lineup?.item04?.link?.url}
                  table={ACF?.lineup?.item04?.table}
                  freeblock={ACF?.lineup?.item04?.freeblock}
                />
              )}

              {ACF?.lineup?.item05?.title && (
                <LineUp
                  image={ACF?.lineup?.item05?.img}
                  image2={ACF?.lineup?.item05?.img2}
                  heading={ACF?.lineup?.item05?.title}
                  link={ACF?.lineup?.item05?.link?.url}
                  table={ACF?.lineup?.item05?.table}
                  freeblock={ACF?.lineup?.item05?.freeblock}
                />
              )}
            </ul>
          </div>
        </div>
        {differences?.isDisplay && (
          <div className="l-container-sm py-16 pc:py-[120px]">
            <h3 className="text-center text-xl font-bold tracking-[.06em] pc:text-[40px]">
              他社製品とは、
              <span className="text-primary">
                <strong className="relative before:absolute before:left-[0.3em] before:top-[-1.2em] before:content-['.']">
                  こ
                </strong>
                <strong className="relative before:absolute before:left-[0.3em] before:top-[-1.2em] before:content-['.']">
                  こ
                </strong>
                が違う
              </span>
            </h3>
            <ul className="mt-10 space-y-6">
              <div className="mt-4">
                {<Difference number={1} {...differences.difference01} />}
              </div>
              <div className="border-t border-solid border-[#E0E0E0]">
                {<Difference number={2} {...differences.difference02} />}
              </div>
            </ul>
          </div>
        )}

        {/* ----------------フリーブロック--------------- */}
        {(ACF?.freeblock01?.title ||
          ACF?.freeblock02?.title ||
          ACF?.freeblock03?.title ||
          ACF?.freeblock04?.title ||
          ACF?.freeblock05?.title) && (
          <div className="l-container-sm pb-16 pc:pb-[120px]">
            <FreeBlock01
              heading={ACF?.freeblock01?.title}
              text={ACF?.freeblock01?.text}
              img={ACF?.freeblock01?.img}
              img2={ACF?.freeblock01?.img2}
            />
            <FreeBlock01
              heading={ACF?.freeblock02?.title}
              text={ACF?.freeblock02?.text}
              img={ACF?.freeblock02?.img}
              img2={ACF?.freeblock02?.img2}
            />
            <FreeBlock01
              heading={ACF?.freeblock03?.title}
              text={ACF?.freeblock03?.text}
              img={ACF?.freeblock03?.img}
              img2={ACF?.freeblock03?.img2}
            />
            <FreeBlock01
              heading={ACF?.freeblock04?.title}
              text={ACF?.freeblock04?.text}
              img={ACF?.freeblock04?.img}
              img2={ACF?.freeblock04?.img2}
            />
            <FreeBlock01
              heading={ACF?.freeblock05?.title}
              text={ACF?.freeblock05?.text}
              img={ACF?.freeblock05?.img}
              img2={ACF?.freeblock05?.img2}
            />
          </div>
        )}

        {/* ---------------cta--------------- */}
        <div className="bg-[#FAFAFA] py-24">
          <div className="l-container-sm">
            <Cta02 />
          </div>
        </div>
        {/* ---------------他の製品も見る--------------- */}
        <div className="bg-white py-[120px] pr-0">
          <h2 className="text-center text-3xl font-bold">他の製品も見る</h2>
          <div className="l-container-scroll l-container-sm mt-14">
            <Table01 />
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default PostProduct;

export const query = graphql`
  query PostProduct($id: String!) {
    wpProduct(id: { eq: $id }) {
      title
      productAcf {
        enname
        catch
        caution
        colors {
          color01
          color02
          color03
          color04
        }
        images {
          image01 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image02 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image03 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image04 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image05 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image06 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image07 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image08 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image09 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image10 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        isshowfeatures
        features {
          feature01 {
            heading
            text
          }
          feature02 {
            heading
            text
          }
          feature03 {
            heading
            text
          }
          feature04 {
            heading
            text
          }
          feature05 {
            heading
            text
          }
        }
        differences {
          isDisplay
          difference01 {
            catchText: catch
            comparison {
              pc {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              sp {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            label
            myproduct {
              title
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            normal {
              title
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          difference02 {
            catchText: catch
            comparison {
              pc {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              sp {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            label
            myproduct {
              title
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            normal {
              title
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        freeblock01 {
          text
          title
          img {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
          img2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
        freeblock02 {
          text
          title
          img {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
          img2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
        freeblock03 {
          text
          title
          img {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
          img2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
        freeblock04 {
          text
          title
          img {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
          img2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
        freeblock05 {
          text
          title
          img {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
          img2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
        price
        notaxprice
        lineup {
          item01 {
            title
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            img2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            link {
              url
            }
            table {
              header
              body
            }
            freeblock
          }
          item02 {
            title
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            img2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            link {
              url
            }
            table {
              header
              body
            }
            freeblock
          }
          item03 {
            title
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            img2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            link {
              url
            }
            table {
              header
              body
            }
            freeblock
          }
          item04 {
            title
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            img2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            link {
              url
            }
            table {
              header
              body
            }
            freeblock
          }
          item05 {
            title
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            img2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            link {
              url
            }
            table {
              header
              body
            }
            freeblock
          }
        }
        overview {
          overview01 {
            description
            title
          }
          overview02 {
            description
            title
          }
          overview03 {
            description
            title
          }
          overview04 {
            description
            title
          }
        }
      }
    }
  }
`;
