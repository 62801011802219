import React from "react";
import { Props } from "../../types/RuledLineList03";

/**
 * 罫線付きリストを返します。ulでラップしてください
 * PRODUCT FEATURESに使用。
 */
export const Presenter: React.VFC<Props> = ({
  title,
  TagName = "h3",
  index,
}) => {
  return (
    <li className="list-none border-b border-solid border-[#E0E0E0] pt-8 pb-6 pc:py-8">
      <span className="text-[20px] text-primary pc:text-[32px]">
        {`0` + index}
      </span>
      <TagName
        className={`mt-4 text-xl font-bold pc:text-2xl`}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    </li>
  );
};
