import React from "react";

import { Props } from "../../types/ProductInfo01";
import { RuledLineList02 } from "../RuledLineList02";
import { Slider02 } from "../Slider02";

/**
 * 製品情報など、商品詳細ページに使用
 */
export const Presenter: React.VFC<Props> = ({
  smallLabel,
  label,
  images,
  overviews,
  colors,
  price,
  notaxprice,
  caution,
}) => {
  // カラーチェック
  const hasColor =
    colors && Object.values(colors).every((color) => color === null);
  return (
    <div className="flex flex-col pc:flex-row">
      {/* 画像エリア */}
      {/* メイン画像 */}
      {images && (
        <div className="flex-shrink-0">
          <Slider02 images={images} />
        </div>
      )}
      {/* テキストエリア */}
      <div className="mt-10 pc:ml-[55px] pc:mt-0">
        <span className="block text-xs font-bold text-primary pc:text-sm">
          {smallLabel}
        </span>
        <h1 className="mt-3 inline-block text-xl font-bold text-dark pc:text-3xl">
          {label}
        </h1>
        {overviews && (
          <dl className="my-6 pc:mt-10">
            {overviews &&
              Object.values(overviews).map(
                (overview, index) =>
                  overview.title &&
                  overview.description && (
                    <RuledLineList02
                      key={index}
                      title={overview.title}
                      content={overview.description}
                    />
                  )
              )}
          </dl>
        )}

        {colors && !hasColor && (
          <ul className="mt-8 pc:mt-10">
            カラー：
            {Object.values(colors).map(
              (color, index) =>
                color && (
                  <li className="inline px-1 text-sm pc:text-base" key={index}>
                    {color}
                  </li>
                )
            )}
          </ul>
        )}

        <div className="mt-4 text-sm font-medium pc:mt-6 pc:text-xl">
          ¥{price}{" "}
          <span className="text-sm font-normal pc:text-base">
            （税抜：¥{notaxprice}）
          </span>
        </div>
        {caution && (
          <p
            className="mt-[10px] text-xs"
            dangerouslySetInnerHTML={{
              __html: caution,
            }}
          ></p>
        )}
      </div>
    </div>
  );
};
