import React from "react";
import { Button01 } from "../Button01";

/**
 * cta2つ目
 * 製品詳細ぺーじで使用
 */
export const Presenter: React.VFC = () => {
  const items = [
    "実物を見てみたい",
    "とりあえず話が聞きたい",
    "本当に質がいいの？",
    "商品の魅力を直接聞きたい",
  ];
  return (
    <div className="rounded-lg bg-white px-6 py-8 pc:px-16 pc:pt-14 pc:pb-16">
      <p className="text-center text-xl font-bold pc:text-3xl">
        ミスギの製品にご興味を持っていただけましたか？
      </p>
      <ul className="mx-auto mt-4 flex w-[280px] flex-col items-center space-y-2 pc:mt-8 pc:w-full pc:flex-row pc:space-x-4">
        {items.map((item, index) => (
          <li
            key={index}
            className={`inline-block rounded-2xl bg-secondary px-5 py-2 text-xs font-bold 
            text-primary pc:whitespace-nowrap pc:text-base ${
              index % 2 ? `ml-auto` : `mr-auto`
            }`}
          >
            {item}
          </li>
        ))}
      </ul>
      <p className="mt-8 mb-4 text-center text-base">
        どんなことでも、
        <br className="pc:hidden" />
        お気軽にご相談ください！
      </p>
      <div className="flex justify-center">
        <Button01
          label={"ご相談はこちらから"}
          link={"/contact"}
          isWhite={false}
          isPageTransition={false}
          className={`pc:min-w-[490px] pc:text-2xl`}
        />
      </div>
    </div>
  );
};
