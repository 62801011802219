// import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import { Link01 } from "../Link01";
import { Props } from "../../types/Table01";
import ScrollHint from "scroll-hint";
import { WpImage } from "../WpImage";
import * as Table01Styles from "./Table01.module.scss";

import recommendation from "../../images/table/recommendation.png";
import recommendationSp from "../../images/table/recommendationSp.png";

/**
 * 表を返します。
 * 横スクロールします
 * 製品比較表に使用。
 */

export const Presenter: React.VFC<Props> = ({ heads, rows }) => {
  useEffect(() => {
    new ScrollHint(".js-scrollable", {
      i18n: {
        scrollable: "スクロールできます",
      },
    });
  }, []);
  return (
    <div className={`js-scrollable w-[calc(100vw-40px)] pc:w-full`}>
      <table>
        <thead>
          <tr className="relative h-[50px] bg-white pc:h-[80px]">
            <td className="absolute top-0 left-[64px] w-[147px] pc:left-[132px] pc:w-[192px]">
              <img
                src={recommendation}
                alt={"当社おすすめ"}
                className={`hidden h-[760px] w-[190px] pc:block`}
              />
              <img
                src={recommendationSp}
                alt={"当社おすすめ"}
                className={`h-[545px] w-[145px] pc:hidden`}
              />
            </td>
            <td className="absolute top-0 left-[214px] w-[147px] pc:left-[330px] pc:w-[192px]">
              <img
                src={recommendation}
                alt={"当社おすすめ"}
                className={`hidden h-[760px] w-[190px] pc:block`}
              />
              <img
                src={recommendationSp}
                alt={"当社おすすめ"}
                className={`h-[545px] w-[145px] pc:hidden`}
              />
            </td>
          </tr>
          <tr>
            <th></th>
            {heads.map((head, index) => (
              <th
                className={`font-normal pc:py-1 ${Table01Styles.tableHead}`}
                key={index}
              >
                {head[2] && (
                  <WpImage
                    image={head[2]}
                    className="relative z-10 mx-auto mt-2 max-h-[61px] max-w-[110px] pc:mt-0 pc:max-h-[83px] pc:max-w-[150px]"
                  />
                )}
                <p
                  className="text-xs font-normal pc:text-base pc:font-medium"
                  dangerouslySetInnerHTML={{
                    __html: head[0],
                  }}
                ></p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-['#E0E0E0'] border-t border-solid">
          {rows.map((row, index) => (
            <tr
              key={index}
              className={`${index % 2 ? `bg-secondary` : `bg-white`}`}
            >
              <th className="h-[83px] min-w-[64px] text-xs pc:h-[115px] pc:min-w-[132px] pc:text-xl">
                {index === 0 && `材質`}
                {index === 1 && `最大荷重`}
                {index === 2 && `耐久性`}
                {index === 3 && `静音性`}
              </th>
              {row.map((item, key) => (
                <td
                  className="h-[83px] min-w-[152px] text-center text-sm font-medium pc:h-[115px] pc:min-w-[200px] pc:text-xl"
                  key={key}
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                ></td>
              ))}
            </tr>
          ))}
          <tr className="border-['#E0E0E0'] h-auto border-t  border-solid pc:h-[86px]">
            <td></td>
            {heads.map((head, index) => (
              <td key={index} className="p-6 text-center text-sm">
                <Link01 label={"詳しく見る"} link={head[1] || "/"} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
